var advancedSearchController = {
    initialize: function () {
        if (window.location.pathname == '/search') {
            var searchType = getDecodedUrlParm('s');
            var keyword = getDecodedUrlParm('k');
            if (keyword) {
                $('input[name="SEARCHKEYWORDSEF"]').val(keyword);
            }
            var productId = getDecodedUrlParm('pid');
            if (productId) {
                $('input[name="SEARCHPRODUCTIDEF"]').val(productId);
            }
            var sku = getDecodedUrlParm('sku');
            if (sku) {
                $('input[name="SEARCHSKUEF"]').val(sku);
            }
            var categoryId = getDecodedUrlParm('c');
            if (categoryId) {
                $('select[name="SEARCHCATEGORYDD"').val(categoryId);
            }
        }
    },
    search: function (control) {
        var $container = $(control).parents('#c-advanced-search-container');
        var keyword = $container.find('input[name="SEARCHKEYWORDSEF"]').val();
        var productId = $container.find('input[name="SEARCHPRODUCTIDEF"]').val();
        var sku = $container.find('input[name="SEARCHSKUEF"]').val();
        var categoryId = $container.find('select[name="SEARCHCATEGORYDD"]').val();
        var searchType = $container.find('input[name="SEARCHTYPE"]').val();

        if (!advancedSearchController.validate($container)) {
            return;
        }

        var searchResultsURL = new JDURL(advancedProductSearchGlobals.searchResultsURL);
        searchResultsURL.appendParam('k', keyword);
        if (hasValue(productId)) {
            searchResultsURL.appendParam('pid', productId);
        }
        if (hasValue(sku) && sku > 0) {
            searchResultsURL.appendParam('sku', sku);
        }
        if (hasValue(categoryId) && categoryId > 0) {
            searchResultsURL.appendParam('c', categoryId);
        }
        if (hasValue(searchType)) {
            searchResultsURL.appendParam('s', searchType);
        }

        //
        gotoURL(searchResultsURL.getUrl());
    },
    validate: function (parent) {
        var validateItemOptions = {
            customParent: true,
            parent: parent
        };
        var validator = new Validator();
        var skuValidateItem = newOptionalNumberValidateItem('SEARCHSKUEF', 'SKU', validateItemOptions);
        var validateOneItemForSearch = function () {
            var keywordsValidateItem = newValidateItem('SEARCHKEYWORDSEF', 'Keywords', validateItemOptions);
            var productIdValidateItem = newValidateItem('SEARCHPRODUCTIDEF', 'Product ID', validateItemOptions);
            var categoryValidateItem = newValidateItem('SEARCHCATEGORYDD', 'Category', validateItemOptions);
            var skuNotBlankValidateItem = newValidateItem('SEARCHSKUEF', 'SKU', validateItemOptions);

            return keywordsValidateItem.isValid() || productIdValidateItem.isValid() || categoryValidateItem.isValid() || (skuValidateItem.isValid() && skuNotBlankValidateItem.isValid());
        };
        validator.addItem(skuValidateItem);
        validator.addItem(new CustomValidateItem(null, validateOneItemForSearch, 'At least one search value must be populated'));

        return validator.isValid();
    },
    checkEnter: function (event, control) {
        if (isEnterEvent(event)) {
            advancedSearchController.search(control);
        }
    }
};
$(function () {
    advancedSearchController.initialize();
});
